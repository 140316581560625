import React, {useEffect, useState} from "react";
import { CountryRegionData } from "react-country-region-selector";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    label: {
        padding: '7px 0'
    }
}));

function CountryRegionMUISelectors(props) {
    const classes = useStyles();

    function changeCountry(event) {
        props.handleChange(event)
    }

    function isBadCountry(country) {
        return country === "Balkans" || country === "Belarus" || country === "Burma" || country === "Cote D'Ivoire (Ivory Coast)" || country === "Cuba" || country === "Congo, Republic of the (Brazzaville)" || country === "Iran, Islamic Republic of" || country === "Iraq" || country === "Liberia" || country === "Myanmar" || country === "Korea, Republic of" || country === "Sudan" || country === "Syrian Arab Republic" || country === "Zimbabwe" || country === "Russian Federation" || country === "Afghanistan"
    }

    useEffect(() => {
        CountryRegionData.map((option, index) => {
            if (option[0] === 'United States') {
                props.handleChange(option)
            }
        })
    }, [])

    return (
        <>
            <Grid item xs={12} sm={2}>
                <div className={classes.label}>Country:</div>
            </Grid>
            <Grid item xs={12} sm={10}>
                <div className="patron-form-input" id="patron-country-input">
                    <TextField
                        id="patron-country"
                        value={props.country}
                        size="small"
                        fullWidth
                        select
                        variant="outlined"
                        onChange={changeCountry}
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                        {CountryRegionData.filter(country => !isBadCountry(country[0])).map((option, index) => (
                            <MenuItem id="country" key={option[0]} value={option}>
                                {option[0]}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </Grid>
        </>
    )
}

export default CountryRegionMUISelectors;
