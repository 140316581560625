import {gql} from "@apollo/client";

export const UPDATE_PRODUCT_ACTIVITY_IN_PROCESS_MUTATION = gql`
mutation updateProductActivityInProcess($product: ID!, $quantity: Int!) {
  createProductActivityInProcess(input: {
    data: {
      quantity: $quantity,
      product: $product
    }}) {
      productActivityInProcess {
        id
      }
    }
}
`;
