import React, {useContext, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl} from "@material-ui/core";
import CustomPhoneInput from "./PhoneInput";
import CountryRegionMUISelectors from "./CountryRegionInput"
import {FormContext} from "../../contexts/FormContext";
import Grid from "@material-ui/core/Grid";
import {CountryRegionData} from "react-country-region-selector";
import RegionInput from "./RegionInput";
import {Controller} from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const PatronInformationBlock = (props) => {
    const formContext = useContext(FormContext);
    const [country, setCountry] = useState("United States");
    const [countryShortCode, setCountryShortCode] = useState('us');
    const [selectedDate, setSelectedDate] = React.useState('');
    const useStyles = makeStyles((theme) => ({
        root: {
            margin: 'auto',
            display: 'block'
        },
        label: {
            height: '44px',
            verticalAlign: 'middle',
            display: 'inline'
        },
        patronFormInput: {
            verticalAlign: 'middle',
            display: 'inline'
        }
    }));

    const {register, errors, control} = props.form;

    const setFirstName = function (event) {
        formContext.setFirstName(event.target.value)
        props.handleChange(event);
    }

    const setLastName = function (event) {
        formContext.setLastName(event.target.value)
        props.handleChange(event);
    }

    const updateShortCountryCode = function () {
        CountryRegionData.forEach(function (option, index) {
            if (option[0] === country) {
                setCountryShortCode(option[1].toLowerCase());
            }

            setCountryShortCode('us');
        })
    }

    const [phoneTypeResponse, setPhoneTypeResponse] = useState("Home");

    const handlePhoneChange = function (event) {
        setPhoneTypeResponse(event.target.value);
        props.handleChange(event)
    }

    const onCountryChanged = function (event) {
        updateShortCountryCode()
        if (event.target === undefined) {
            setCountry(event)
            props.handleChange({
                target: {
                    name: "patronCountry",
                    value: event[0]
                }
            });
            return
        }

        setCountry(event.target.value)
        props.handleChange({
            target: {
                name: "patronCountry",
                value: event.target.value[0]
            }
        });
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const classes = useStyles();

    let phoneTypes = ["Home", "Mobile", "Work"];


    return (
        <div className="block" id={"patron-block-" + props.block.id}>
            <div id="patron-header"><h2>{props.block.label}</h2></div>
            <br/>
            <FormControl id="patron-form" component="fieldset" classes={{
                root: classes.root
            }}>
                <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.label}>First Name<span style={{ color: "red" }}>*</span>:</div>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <div className={classes.patronFormInput} id="patron-first-input">
                            <TextField error={errors.patronFirstName} name="patronFirstName"
                                       inputRef={register({required: true})} size="small" fullWidth variant="outlined"
                                       id="patron-first" onChange={setFirstName}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.label}>Last Name<span style={{ color: "red" }}>*</span>:</div>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <div className={classes.patronFormInput} id="patron-last-input">
                            <TextField error={errors.patronLastName} name="patronLastName"
                                       inputRef={register({required: true})} size="small" fullWidth variant="outlined"
                                       id="patron-last" onChange={setLastName}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.label}>Email<span style={{ color: "red" }}>*</span>:</div>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <div className={classes.patronFormInput} id="patron-email-input">
                            <TextField error={errors.patronEmail} name="patronEmail"
                                       inputRef={register({required: true})} size="small" fullWidth variant="outlined"
                                       id="patron-email" onChange={props.handleChange}/>
                        </div>
                    </Grid>
                    {props.block['PatronFields'] != null && props.block['PatronFields']['address'] && (
                        <>

                            <CountryRegionMUISelectors name="patronCity" id="patron-city" placeholder="City" country={country}
                                                       handleChange={onCountryChanged}/>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>Address{props.block['PatronFields']['address']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.patronFormInput} id="patron-address-input">
                                    <TextField error={errors.patronAddress} name="patronAddress"
                                               inputRef={register({required: props.block['PatronFields']['address']['required']})} size="small" fullWidth
                                               variant="outlined" id="patron-address" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>Address 2:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.patronFormInput} id="patron-address-2-input">
                                    <TextField name='patronAddress2' size="small" fullWidth variant="outlined" id="patron-address-2" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>City{props.block['PatronFields']['address']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.patronFormInput} id="patron-address-input">
                                    <TextField error={errors.patronCity} name="patronCity"
                                               inputRef={register({required: props.block['PatronFields']['address']['required']})} size="small" fullWidth
                                               variant="outlined" id="patron-address" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                            <RegionInput form={props.form} country={country} handleChange={props.handleChange}/>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>Zip{props.block['PatronFields']['address']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.patronFormInput} id="patron-zip-input">
                                    <TextField error={errors.patronZip} name="patronZip"
                                               inputRef={register({required: props.block['PatronFields']['address']['required']})} size="small" fullWidth
                                               variant="outlined" id="patron-zip" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                        </>
                    )}
                    {props.block['PatronFields'] != null && props.block['PatronFields']['phone'] && (
                        [...props.block['PatronFields']['phone'].keys()].map(key => (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        {props.block['PatronFields']['phone'][key]["type"] === "home" && (
                                            <div className={classes.label}>Home Phone{props.block['PatronFields']['phone'][key]['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                                        )}
                                        {props.block['PatronFields']['phone'][key]["type"] === "mobile" && (
                                            <div className={classes.label}>Mobile Phone{props.block['PatronFields']['phone'][key]['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                                        )}
                                        {props.block['PatronFields']['phone'][key]["type"] === "work" && (
                                            <div className={classes.label}>Work Phone{props.block['PatronFields']['phone'][key]['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                                        )}
                                        {props.block['PatronFields']['phone'][key]["type"] === "custom" && (
                                            <div className={classes.label}>Phone{props.block['PatronFields']['phone'][key]['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={(props.block['PatronFields']['phone'][key]['type'] === "custom") ? 7 : 10}>
                                        <TextField
                                            inputRef={register({required: props.block['PatronFields']['phone'][key]['required'] || props.block['PatronFields']['phone'][key]['required'] || props.block['PatronFields']['phone'][key]['required']})}
                                            name={"patronPhone" + props.block['PatronFields']['phone'][key]["type"]} type="tel" size="small" fullWidth variant="outlined" id="patron-phone" placeholder='Enter phone number' onChange={props.handleChange}/>
                                    </Grid>

                                    { props.block['PatronFields']['phone'][key]['type'] === "custom" && (
                                        <Grid container sm={2}>
                                            <Grid item xs={1} sm={4}>
                                                <div className={classes.label}>Type<span style={{ color: "red" }}>*</span>:</div>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <FormControl>
                                                    <Select
                                                        value={phoneTypeResponse}
                                                        name={"patron-phone-type"}
                                                        onChange={handlePhoneChange}
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                        }}
                                                        inputRef={register({ required: true })}
                                                    >
                                                        {phoneTypes.map((phoneType) => (
                                                            <MenuItem key={phoneType} value={phoneType}>
                                                                {phoneType}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                        </Grid>
                                        </Grid>
                                    )}
                                </>
                            )
                        ))}
                        {props.block['PatronFields']["dob"] && (
                        <>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>Date of Birth{props.block['PatronFields']['dob']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.patronFormInput} id="patron-first-input">
                                    <TextField error={errors.patronDob} name="patronDob" inputRef={register({required: props.block["PatronFields"]["dob"]["required"]})} onChange={handleDateChange} size="small" placeholder="mm/dd/yyyy" fullWidth variant="outlined" type='date' id="patron-dob" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                        </>
                    )}
                    {props.block['PatronFields']["birthday"] && (
                        <>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>Birthday{props.block['PatronFields']['dob']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.patronFormInput} id="patron-first-input">
                                    <TextField error={errors.patronBirthday} name="patronBirthday" inputRef={register({required: props.block["PatronFields"]["birthday"]["required"]})} onChange={handleDateChange} size="small" placeholder="mm/dd" fullWidth variant="outlined" type='date' id="patron-birthday" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                        </>
                    )}
                </Grid>
            </FormControl>
        </div>
    )
}

export default PatronInformationBlock;
