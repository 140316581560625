import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import ProductBlock from "./ProductBlock";

const ProductsBlock = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
    }));

    const classes = useStyles();

    return (
        <div className="block" id={"product-block-" + props.block.id}>
            <Grid container spacing={1}>
                {props.block.image &&
                    <>
                <Grid item xs={12} sm={4}>
                    <img src={props.block.image.url}/>
                </Grid>
                <Grid item xs={12} sm={8}>
                    {props.block.header && (
                        <Grid item xs={12} sm={12}>
                            <div className={classes.productDescription} dangerouslySetInnerHTML={{__html: props.block.header}} />
                        </Grid>
                    )}
                    {[...props.block.products.keys()].map(key => (
                    <ProductBlock key={props.block.products[key].id} block={props.block.products[key]} checkboxDisplay={props.block.checkboxDisplay} />
                    ))}
                    </Grid>
                        {props.block.footer && (
                            <Grid item xs={12} sm={12}>
                                <div className={classes.productDescription} dangerouslySetInnerHTML={{__html: props.block.footer}} />
                            </Grid>
                        )}
                    </>
                }

                {!props.block.image &&
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12} sm={12}>
                        <div dangerouslySetInnerHTML={{__html: props.block.header}} />
                    </Grid>
                    {[...props.block.products.keys()].map(key => (
                    <ProductBlock key={props.block.products[key].id} block={props.block.products[key]} handleChange={props.handleChange} checkboxDisplay={props.block.checkboxDisplay} />
                ))}
                    <Grid item xs={12} sm={12}>
                        <div dangerouslySetInnerHTML={{__html: props.block.footer}} />
                    </Grid>
                </Grid>
                }

            </Grid>
        </div>
    )
}

export default ProductsBlock;