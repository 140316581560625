import React, {useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Controller} from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    label: {
        padding: '7px 0'
    }
}));

function RegionInput(props) {
    const [region, setRegion] = useState(undefined);

    const classes = useStyles();

    function changeRegion(event) {
        setRegion(event.target.value)
        props.handleChange(event)
    }

    const getRegions = country => {
        if (!country) {
            return [];
        }

        let regions = country[2].split("|").map(regionPair => {
            let [regionName, regionShortCode = null] = regionPair.split("~");
            return regionName;
        });

        if (regions.length > 0 && country[0] === 'United States') {
            regions.push('Canada')
            regions.sort()
        }

        return regions;
    };

    let regions = [...getRegions(props.country)]
    const { register, errors, control } = props.form;

    return (
        <>
            <Grid item xs={12} sm={2}>
                <div className={classes.label}>{(props.country[0] === 'United States') ? "State" : "Region"}<span style={{ color: "red" }}>*</span>:</div>
            </Grid>
            <Grid item xs={12} sm={10}>
                <div className="patron-form-input" id="patron-address-input">
                    <Controller
                        name="patronRegion"
                        defaultValue=""
                        rules={{required: true}}
                        control={control}
                        render={({ name, onBlur, onChange, value }) => (
                    <TextField
                        id="patron-region"
                        value={region}
                        size="small"
                        fullWidth
                        select
                        error={errors.patronRegion}
                        onBlur={onBlur}
                        name="patronRegion"
                        inputRef={register({ required: true })}
                        variant="outlined"
                        onChange={(event) => {
                            changeRegion(event)
                            onChange(event)
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                        {regions.map(
                            (option, index) => (
                                <MenuItem id="region" key={option} value={option}>
                                    {option}
                                </MenuItem>
                            )
                        )}
                    </TextField>
                    )}/>
                </div>
            </Grid>
        </>
    )
}

export default RegionInput;
