import React, {useContext, useEffect, useState} from "react";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Cart from "./Cart";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@material-ui/core";
import {CartContext} from "../../contexts/CartContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FormContext} from "../../contexts/FormContext";
import DonationSetting from "../DonationSetting/DonationSetting";
import * as ReactGA from "react-ga";
import {Alert} from "@mui/material";
import {useMutation, useQuery} from "@apollo/client";
import {UPDATE_PRODUCT_ACTIVITY_IN_PROCESS_MUTATION} from "./UpdateProductActivityInProcessMutation.mutation";

const CheckoutForm = (props) => {
    const venue = window.location.pathname.split("/")[1]
    const form = window.location.pathname.split("/")[2]

    const cartContext = useContext(CartContext);
    const formContext = useContext(FormContext);
    const formStatus = {
        NOT_SUBMITTED: 'not submitted',
        PROCESSING: 'processing',
        SUCCESS: 'success',
        FAILURE: 'failure'
    }

    const [currentFormStatus, setCurrentFormStatus] = useState(formStatus.NOT_SUBMITTED);
    const [checkoutForm, setCheckoutForm] = useState();
    const [custRefInput, setCustRefInput] = useState();

    const [productsLimitReached, setProductsLimitReached] = useState([]);

    const successURL = () => {
        return process.env.REACT_APP_SUBMIT_URL + "/" + venue + "/" + form + "/success"
    }

    const webhookURL = () => {
        return process.env.REACT_APP_SUBMIT_URL + "/webhook/success"
    }

    const failureURL = () => {
        return process.env.REACT_APP_SUBMIT_URL + "/" + venue + "/" + form + "/failure"
    }

    const sendGoogleAnalytics = () => {
        if (formContext.form.googleAnaltyicsId === undefined) {
            return
        }

        ReactGA.initialize(formContext.form.googleAnaltyicsId);
        ReactGA.plugin.require('ecommerce')
        Object.keys(cartContext.products).forEach(key => (
            ReactGA.plugin.execute('ecommerce', 'addProduct', {
                id: key,        // Transaction ID. Required.
                name: cartContext.products[key].name,          // Product name. Required.
                sku: key,                 // SKU/code.
                category: '',         // Category or variation.
                price: cartContext.products[key].price.toFixed(2),                 // Unit price.
                quantity: cartContext.products[key].quantity,                 // Quantity.
            })
        ))

        if (cartContext.donation) {
            ReactGA.plugin.execute('ecommerce', 'addProduct', {
                id: "donation",        // Transaction ID. Required.
                name: (cartContext.donation.name !== "") ? cartContext.donation.name : "Donation",          // Product name. Required.
                sku: "donation",                 // SKU/code.
                category: '',         // Category or variation.
                price: cartContext.donation.price.toFixed(2),                 // Unit price.
                quantity: cartContext.donation.quantity,                 // Quantity.
            })
        }

        if (cartContext.transactionFee) {
            ReactGA.plugin.execute('ecommerce', 'addProduct', {
                id: "transactionFee",        // Transaction ID. Required.
                name: cartContext.transactionFee.label,          // Product name. Required.
                sku: "transactionFee",                 // SKU/code.
                category: '',         // Category or variation.
                price: cartContext.transactionFee.amount.toFixed(2),                 // Unit price.
                quantity: 1,                 // Quantity.
            })
        }

        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
            id: cartContext.custref,
            revenue: cartContext.total,
        });
        ReactGA.plugin.execute('ecommerce', 'send');
        ReactGA.plugin.execute('ecommerce', 'clear');
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            margin: "auto",
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            "&:hover": {
                boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
            },
        },
        field: {
            width: "100%"
        },
        payButton: {
            marginTop: '30px'
        },
        creditCardImages: {},
        label: {
            padding: '15px 0'
        }
    }));

    const classes = useStyles();

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    useEffect(() => {
        let uuid = uuidv4()
        setCustRefInput(uuid)
        cartContext.setCustRef(uuid)
    }, [])

    const [addProductActivityInProcess, {loading, error, data}] = useMutation(UPDATE_PRODUCT_ACTIVITY_IN_PROCESS_MUTATION, {
        fetchPolicy: "network-only",
        variables: {
            product: "",
            quantity: 0
        },
    });

    const [mustSelectProduct, setMustSelectProduct] = useState(false);

    useEffect(() => {
        let products_filtered = Object.keys(cartContext.products).filter(key => key !== "form-fee")
        let products = Object.keys(cartContext.products)
        if (products_filtered.length === 0 && products_filtered.length !== products.length && !cartContext.donation) {
            setMustSelectProduct(true);
        }

        Object.keys(cartContext.products).forEach(key => {
            let inProcess = 0;

            if (!cartContext.products[key].quantityAvailable) {
                setProductsLimitReached([]);
                return
            }

            if (cartContext.products[key].product_activity_in_processes) {
                cartContext.products[key].product_activity_in_processes.forEach((process) => {
                    inProcess += process.quantity
                })
            }

            if ((cartContext.products[key].quantityAvailable - inProcess - cartContext.products[key].quantity - cartContext.products[key].amountSold) < 0) {
                setProductsLimitReached([...productsLimitReached, cartContext.products[key]])
            }

            addProductActivityInProcess({
                variables: {
                    product: key,
                    quantity: cartContext.products[key].quantity
                }
            })
        })
    }, [])

    const submitForm = async function () {
        sendGoogleAnalytics()
        if (cartContext.total === 0 && !formContext.isDataCollectionFormType()) {
            alert("Please select a product or make a donation to continue. ")
            return false
        }

        await props.handleFormSubmission()

        checkoutForm.submit()
    }

    switch (currentFormStatus) {
        case formStatus.PROCESSING:
            return (
                <CircularProgress/>
            )
        case formStatus.SUCCESS:
            return (
                <div className="success_block" dangerouslySetInnerHTML={{__html: formContext.form.successHTML}}/>
            )
        case formStatus.FAILURE:
            return (
                <div className="success_block" dangerouslySetInnerHTML={{__html: formContext.form.failureHTML}}/>
            )
        case formStatus.NOT_SUBMITTED:
        default:
            return (<div className="block">
                    {(cartContext.donationSettings && cartContext.donationSettings.placement === "aboveShoppingCart") && (
                        <DonationSetting
                            donationSetting={cartContext.donationSettings}
                        />
                    )}
                    {(productsLimitReached.map(product => (
                        <Alert severity="error"><div id="product-unavailable" dangerouslySetInnerHTML={{__html: product.productActivityInProgressText.replace("${product}", product.name)}} /></Alert>
                    )))}
                    <CardContent>
                        <Cart/>
                        {(cartContext.donationSettings && cartContext.donationSettings.placement === "belowShoppingCart") && (
                            <DonationSetting
                                donationSetting={cartContext.donationSettings}
                            />
                        )}
                        <Divider/>
                        <form id="checkout-form" ref={setCheckoutForm} action={formContext.tunlUrl()} method="post">
                            <input type="hidden" value={cartContext.total} name="amount" id="amount"/>
                            <input type="hidden" value={formContext.currentBuyer()["name"]} name="cardholdername"
                                   id="cardholdername"/>
                            <input type="hidden" value={formContext.currentBuyer()["address"]} name="street"
                                   id="street"/>
                            <input type="hidden" value={formContext.currentBuyer()["city"]} name="city" id="city"/>
                            <input type="hidden" value={formContext.currentBuyer()["region"]} name="state" id="state"/>
                            <input type="hidden" value={formContext.currentBuyer()["country"]} name="country"
                                   id="country"/>
                            <input type="hidden" value={formContext.currentBuyer()["zip"]} name="zip" id="zip"/>
                            <input type="hidden" value={formContext.currentBuyer()["email"]} name="customer_email"
                                   id="customer_email"/>
                            <input type="hidden" value={cartContext.custref} ref={setCustRefInput} name="custref"
                                   id="custref"/>
                            <input type="hidden"
                                   value={(cartContext.donationSettings) ? cartContext.donationSettings.isSelected : false}
                                   name="coveredCosts" id="coveredCosts"/>
                            <input type="hidden" value={formContext.form.name} name="comments" id="comments"/>
                            <input type="hidden" value={successURL()} name="success_url" id="success_url"/>
                            <input type="hidden" value={webhookURL()} name="webhook_url" id="webhook_url"/>
                            <input type="hidden" value={failureURL()} name="cancel_url" id="cancel_url"/>
                            <input type="hidden" value={formContext.form.venue.webhookErrorEmail}
                                   name="webhook_error_email" id="webhook_error_email"/>
                            <input type="hidden" value={(formContext.form.venue.hideComments === true) ? "1" : "0"}
                                   name="hide_comments" id="hide_comments"/>
                            <input type="hidden" value={(formContext.form.venue.hideCustRef === true) ? "1" : "0"}
                                   name="hide_cust_ref" id="hide_cust_ref"/>
                            <Grid container spacing={3}>
                                <Grid container justify="center">
                                    <Button
                                        id="submit-button"
                                        className={classes.payButton}
                                        size="large"
                                        onClick={submitForm}
                                        disabled={(productsLimitReached.length > 0 || mustSelectProduct)}
                                        color="secondary">Proceed to Payment</Button>
                                </Grid>
                            </Grid>
                        </form>

                    </CardContent>
                    {formContext.form && formContext.form.testMode && (
                        <div>
                            This Form is in TEST MODE. No credit cards will be processed. Contact Easy-Ware Support
                            before making this link public.
                            Note: Please use 999 or 9999 for the CVV and values between $5 and $8 will always be
                            declined.</div>
                    )}
                </div>
            );
    }
};

const CheckoutPage = (props) => {
    return (
        <CheckoutForm handleFormSubmission={props.handleFormSubmission} form={props.form}/>
    )
}

export default CheckoutPage;
