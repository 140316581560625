import React from "react";
import {
    TwitterShareButton,
    EmailShareButton,
    FacebookShareButton, TwitterIcon, FacebookIcon, EmailIcon
} from "react-share";
import LinkIcon from '@mui/icons-material/Link';
import {Helmet} from "react-helmet";

const DonationSocialMediaShareBlock = (props) => {
    const venue = window.location.pathname.split("/")[1]
    const form = window.location.pathname.split("/")[2]

    return (
        <div className="block" id="donationSocialMediaButtons">
            <Helmet>
                <meta property="og:title" content={venue} />
                <meta property="og:description" content={props.sharedQuote} />
                <meta property="og:image" content={props.sharedImage.url} />
                <meta property="og:image:secure_url" content={props.sharedImage.url} />
            </Helmet>
            <div id="donationSocialMediaHeaderText">{props.headerText}</div>
            <FacebookShareButton quote={props.sharedQuote} url={"https://easy-ware-forms.com/" + venue + "/" + form}>
                <FacebookIcon size={32}
                              round={true}/>
            </FacebookShareButton>
            <TwitterShareButton title={props.sharedQuote} url={"https://easy-ware-forms.com/" + venue + "/" + form}>
                <TwitterIcon size={32}
                             round={true}/>
            </TwitterShareButton>
            <EmailShareButton body={props.sharedQuote} url={"https://easy-ware-forms.com/" + venue + "/" + form}>
                <EmailIcon size={32}
                           round={true}/>
            </EmailShareButton>
            <LinkIcon fontSize="small" style={{marginLeft: "5px", padding: "6px", borderRadius: "15px", fill: "white", backgroundColor: "gray", cursor: "pointer"}}  onClick={() => {navigator.clipboard.writeText("https://easy-ware-forms.com/" + venue + "/" + form)}} />
        </div>
    )
}

export default DonationSocialMediaShareBlock;
