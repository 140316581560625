import React, {useState} from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {Checkbox, FormControl} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const QuestionBlock = (props) => {
    const answers = props.block.question_block_answers.map(answer => (
        answer.text
    ));

    const [response, setResponse] = useState(answers != null ? answers[0] : "");

    const {register, errors, formState} = props.form;
    const errorStyle = {border: '2px solid red'};
    const checkboxErrorStyle = {backgroundColor: 'rgba(255, 0, 0, 0.0)'};
    const { isSubmitted } = formState;

    const hasCheckboxError = () => {
        if (!isSubmitted) {
            return false;
        }
        const selectedCheckboxesCount = props.block.question_block_answers.reduce((count, answer) => {
            const fieldName = `question-block-${props.block.id}-answer-${answer.id}`;
            return count + (props.form.getValues(fieldName) ? 1 : 0);
        }, 0);
        return selectedCheckboxesCount === 0;
    };

    const hasRadioError = () => {
        if (!isSubmitted) {
            return false;
        }
        return Boolean(Object.keys(errors).some(errorKey => errorKey.startsWith(`question-block-${props.block.id}`)));
    };

    const handleChange = function (event) {
        setResponse(event.target.value);
        props.handleChange(event)
    }

    const handleChecked = function (event) {
        if (event.target.checked) {
            setResponse(event.target.value);
            props.handleChange(event)
        } else {
            event.target.value = undefined;
            setResponse(event.target.value);
            props.handleChange(event)
        }
    }

    const handleClick = function (event) {
        if (event.target.value === response) {
            setResponse(undefined)
        }
    }

    const handleButtonClick = function (event) {
        setResponse(event.currentTarget.value);
        handleChange({
            target: {
                name: "question-block-" + props.block.id,
                value: event.currentTarget.value,
            }
        })
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%"
        },

        textarea: {
            maxWidth: "100%"
        }
    }));

    const classes = useStyles();

    console.log('Radio error: ', hasRadioError());
    console.log('errors: ', errors);
    return (
        <div className="block" id={"question-block-" + props.block.id}>
            <span style={{display: 'inline-flex', alignItems: 'center'}}>
                <span dangerouslySetInnerHTML={{__html: props.block.label}}/>
                {props.block.required && (
                    <span style={{color: "red", marginLeft: '5px'}}>*</span>
                )}
            </span>
            <br />

            {props.block.type === "textInput" && (
                <textarea
                    id={"question-block-" + props.block.id}
                    name={"question-block-" + props.block.id}
                    ref={register({required: props.block.required})}
                    onChange={handleChange}
                    cols={props.block.columns}
                    rows={props.block.rows}
                    className={classes.textarea}
                    style={errors[`question-block-${props.block.id}`] ? errorStyle : {}}
                />
            )}

            {props.block.type === "radioSingleChoice" &&
                <FormControl component="fieldset">
                    <div style={hasRadioError() ? checkboxErrorStyle : {}}>
                        {hasRadioError() &&
                            <div style={{color: "red"}}>Please provide an answer.</div>
                        }
                    </div>
                    <RadioGroup aria-label="answer" name={"question-block-" + props.block.id} value={response}
                                onChange={handleChange}>
                        {props.block.question_block_answers.map(answer => (
                            <div className="question-form-answer"><FormControlLabel value={answer.text}
                                                                                    control={<Radio size="small"/>}
                                                                                    label={answer.text}
                                                                                    inputRef={register({required: props.block.required})}/>
                            </div>
                        ))}
                    </RadioGroup>
                </FormControl>
            }

            {props.block.type === "radioMultipleChoice" &&
                <FormControl component="fieldset">
                    <div style={hasRadioError() ? checkboxErrorStyle : {}}>
                        {hasRadioError() &&
                            <div style={{color: "red"}}>Please provide an answer.</div>
                        }
                    </div>
                    {props.block.question_block_answers.map(answer => (
                        <div className="question-form-answer"><FormControlLabel value={answer.text} control={<Radio
                            name={"question-block-" + props.block.id + "-answer-" + answer.id} size="small"
                            onChange={handleChecked} onClick={handleChecked}
                            inputRef={register({required: props.block.required})} value={answer.text}/>}
                                                                                label={answer.text}/></div>
                    ))}
                </FormControl>
            }

            {props.block.type === "checkbox" &&
                <div style={hasCheckboxError() ? checkboxErrorStyle : {}}>
                    <FormControl component="fieldset">
                        {props.block.question_block_answers.map(answer => (
                            <div className="question-form-answer">
                                <FormControlLabel
                                    value={answer.text}
                                    control={<Checkbox

                                        name={`question-block-${props.block.id}-answer-${answer.id}`}
                                        size="small"
                                        onChange={handleChecked}
                                        inputRef={register({required: props.block.required})}
                                    />}
                                    label={answer.text}
                                />
                            </div>
                        ))}
                    </FormControl>
                </div>
            }

            {props.block.type === "yesNo" &&
                <FormControl component="fieldset" style={errors[`question-block-${props.block.id}`] ? errorStyle : {}}>
                    <RadioGroup aria-label="answer" name={"question-block-" + props.block.id} value={response}
                                onChange={handleChange} onClick={handleClick}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <div className="question-form-answer"><FormControlLabel value="yes" control={<Radio
                                    inputRef={register({required: props.block.required})}/>} label="yes"/></div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="question-form-answer"><FormControlLabel value="no" control={<Radio
                                    inputRef={register({required: props.block.required})}/>} label="no"/></div>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            }

            {props.block.type === "dropDown" &&
                <FormControl style={errors[`question-block-${props.block.id}`] ? errorStyle : {}}>
                    <Select
                        value={response}
                        name={"question-block-" + props.block.id}
                        onChange={handleChange}
                        MenuProps={{
                            disableScrollLock: true,
                        }}
                        inputRef={register({required: props.block.required})}
                    >
                        {props.block.question_block_answers.map(answer => (
                            <MenuItem value={answer.text}>
                                <div dangerouslySetInnerHTML={{__html: answer.text}}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }

            {props.block.type === "button" &&
                <Grid container spacing={1}>
                    {props.block.question_block_answers.map(answer => (
                        <Grid item xs={12} sm={3}>
                            <Button className={answer.text === response ? "donationButton selected" : "donationButton"}
                                    size="large"
                                    variant="outlined"
                                    onClick={handleButtonClick}
                                    value={answer.text}
                                    color="secondary">{answer.text}</Button>
                        </Grid>
                    ))}
                </Grid>
            }

            {props.block.type === "date" &&
                <Grid container spacing={1}>
                    {props.block.question_block_answers.map(answer => (
                        <>
                            <Grid item xs={12} sm={2}>
                                <span className={classes.label}>{answer.text} </span>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField name={"question-block-" + props.block.id} type="date"
                                           name={"question-block-" + props.block.id}
                                           placeholder="MM/DD/YYYY"
                                           format="MM/DD/YYYY"
                                           onChange={handleChange}
                                           inputRef={register({required: props.block.required})}
                                />
                            </Grid>
                        </>

                    ))}
                </Grid>
            }
        </div>
    )
};

export default QuestionBlock;
