import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";

const DonationGoalRecentDonorsList = (props) => {
    const venue = window.location.pathname.split("/")[1]
    const form = window.location.pathname.split("/")[2]

    const [currentDonors, setCurrentDonors] = useState([]);
    const [currentNumDonations, setCurrentNumDonations] = useState(0);


    const getDonationGoal = async () => {
        await fetch(process.env.REACT_APP_SUBMIT_URL + "/donationtotals/" + venue + "/" + form, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'GET',
        }).then((res) => {
                res.json().then((json) => {
                    setCurrentDonors(json["donors"].reverse())
                });
            }
        )
    }
    useEffect(() => {
        getDonationGoal()
    }, [])

    return (
        <div id="donationList">
            <b>{props.block.headerText}</b>
            <hr />
            <div id="donors" style={{maxHeight: "200px", overflow: "scroll"}}>
            {currentDonors && currentDonors.map(donor => (
                <>
                    <Grid container item xs={12} spacing={2}>
                        <>
                            <Grid item xs={6} display="flex" style={{textAlign: "left"}}>
                                <div className="donorName">{donor.name}<br /><span className="donorNote">{donor.note}</span></div>
                            </Grid>
                            <Grid item xs={6} display="flex" style={{textAlign: "right"}}>
                                <div className="donorAmount">{(donor.amount) ? "$" + donor.amount : "Private"}</div>
                            </Grid>
                        </>
                    </Grid>
                <hr />
                </>
            ))}
            </div>
        </div>
    )
}

export default DonationGoalRecentDonorsList;
