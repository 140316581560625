import './App.css';
import Form from './components/Form/Form'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import React from "react";
import {CartProvider} from "./contexts/CartContext";
import {FormProvider} from "./contexts/FormContext";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  cache: new InMemoryCache()
});

function App() {  
  return (
      <FormProvider>
          <CartProvider>
              <ApolloProvider client={client}>
                <Form />
              </ApolloProvider>
          </CartProvider>
      </FormProvider>
  );
}

export default App;
