import React from "react";
import Block from "../Block/Block";
import {makeStyles} from "@material-ui/core/styles";

const TwoColumnBlock = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            display: 'flow-root'
        },
        leftColumn: {
            ['@media (min-width:800px)']: {
                width: '50%',
                float: 'left'
            },
        },
        rightColumn: {
            ['@media (min-width:800px)']: {
                width: '50%',
                float: 'right'
            },
        },
    }));
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div id={"left-" + props.block.id} className={classes.leftColumn}>
                {[...props.block.two_column_block.leftColumn.keys()].map(key => (
                    <Block className="left-column" id={"two-column-block-" + props.block.two_column_block.id} form={props.form} handleChange={props.handleChange} block={props.block.two_column_block.leftColumn[key]} />
                ))}
            </div>
            <div id={"right-" + props.block.id} className={classes.rightColumn}>
                {[...props.block.two_column_block.rightColumn.keys()].map(key => (
                    <Block className="right-column" id={"two-column-block-" + props.block.two_column_block.id} form={props.form} handleChange={props.handleChange} block={props.block.two_column_block.rightColumn[key]} />
                ))}
            </div>
        </div>
    )
}

export default TwoColumnBlock;
