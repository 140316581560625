import React, {useEffect, useState} from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import Grid from "@material-ui/core/Grid";
import {Hidden} from "@material-ui/core";

const DonationGoalBlock = (props) => {
    const venue = window.location.pathname.split("/")[1]
    const form = window.location.pathname.split("/")[2]

    const [currentDonationTotal, setCurrentCurrentDonationTotal] = useState(0.00);
    const [currentNumDonations, setCurrentNumDonations] = useState(0);

    const getDonationGoal = async () => {
        await fetch(process.env.REACT_APP_SUBMIT_URL + "/donationtotals/" + venue + "/" + form, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'GET',
        }).then((res) => {
                res.json().then((json) => {
                    if (props.block.biasDollars) {
                        setCurrentCurrentDonationTotal(Math.round(json["total"]) + parseInt(props.block.biasDollars))
                    } else {
                        setCurrentCurrentDonationTotal(Math.round(json["total"]))
                    }

                    if (props.block.biasNumGifts) {
                        setCurrentNumDonations(json["numDonations"] + parseInt(props.block.biasNumGifts))
                    } else {
                        setCurrentNumDonations(json["numDonations"])
                    }
                    console.log(currentDonationTotal / props.block.goal)
                });
            }
        )
    }

    useEffect(() => {
        getDonationGoal()
    }, [])

    const getCurrentNumDonationsText = function () {
        if (currentNumDonations === 1) {
            return props.block.giftsSingleItemText.replace("${currentNumDonations}", currentNumDonations)
        } else {
            return props.block.giftsText.replace("${currentNumDonations}", currentNumDonations)
        }
    }

    return (
        <div>
            <Grid container item xs={12} style={{margin: "-5px"}}>
                <>
                    <Grid item xs={4} display="flex" style={{textAlign: "left"}}>
                        <Hidden xsDown>
                            <div id="currentDonationGoalText">{props.block.toDateText.replace("${currentDonationTotal}", currentDonationTotal.toLocaleString())}</div>
                        </Hidden>
                        <Hidden smUp>
                            <div id="currentDonationGoalText">${currentDonationTotal}</div>
                        </Hidden>
                    </Grid>
                    <Grid item xs={4} display="flex" style={{textAlign: "center "}}>
                        <div id="currentDonationGoalText">{getCurrentNumDonationsText()}</div>
                    </Grid>
                    <Grid item xs={4} display="flex" style={{textAlign: "right"}}>
                        <Hidden xsDown>
                            <div id="currentDonationGoalText">{props.block.goalText.replace("${goal}",props.block.goal.toLocaleString())}</div>
                        </Hidden>
                        <Hidden smUp>
                            <div id="currentDonationGoalText">${props.block.goal}</div>
                        </Hidden>
                    </Grid>
                </>
            </Grid>

            <div id="donationGoalProgrssBar" style={{borderRadius: "30px", borderColor: "blue", borderStyle: "outset", padding: "5px"}}>
                <ProgressBar borderRadius={30} baseBgColor="white" height="100" bgColor="blue" completed={ Math.round((currentDonationTotal / props.block.goal) * 100)  } />
            </div>
        </div>
    )
}

export default DonationGoalBlock;
