import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import {Checkbox, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const DonationGoalNameEntryBlock = (props) => {
    const [includeMyGift, setIncludeMyGift] = useState("false");
    const [includeMyName, setIncludeMyName] = useState("false");

    const handleChange = function (event) {
        setIncludeMyGift(event.target.value === "true" ? "true" : "false")
        props.handleChange(event)
    }

    const handleAnonymouseNameChange = function (event) {
        setIncludeMyName(event.target.value === "true" ? "true" : "false")
        props.handleChange(event)
    }

    return (
        <div id="donationOptInBlock">
            {props.block.optInText}
            <RadioGroup aria-label="answer" name={"includeMyGift"} value={includeMyGift} onChange={handleChange}>
                <FormControlLabel value="true" control={<Radio size="small" label="true"/>}
                                  label="Yes - please include my Gift"/>
                {includeMyGift === "true" && (
                    <div style={{marginLeft: "20px", marginRight: "20px"}}>

                        <RadioGroup aria-label="answer" name="includeMyName" value={includeMyName}
                                    onChange={handleAnonymouseNameChange}>
                            <FormControlLabel value="false" control={<Radio size="small"/>}
                                              label="Please include my name as Anonymous"/>
                            <Grid container item xs={12} spacing={0}>
                                <>
                                    <Grid item xs={12} display="flex" style={{textAlign: "left"}}>
                                        <FormControlLabel value="true" control={<Radio size="small"/>}
                                                          label="Please list my name as:"/>
                                    </Grid>
                                    <Grid item xs={12} display="flex" style={{textAlign: "left"}}>
                                        <TextField name="includeMyGiftName" size="small" fullWidth variant="outlined"
                                                   id="includeMyGiftName" onChange={props.handleChange}/>
                                    </Grid>
                                </>
                            </Grid>
                            <div className="donation-form-gift-name-entry">
                                <FormControlLabel value={true} onChange={props.handleChange}
                                                  control={<Checkbox onChange={props.handleChange}
                                                                     id="includeMyGiftAmount" name="hideMyGiftAmount"
                                                                     size="small"/>}
                                                  label="Please hide the amount of my Gift"/></div>
                            <Grid container item xs={12} spacing={0}>
                                <>
                                    <Grid item xs={12} display="flex" style={{textAlign: "left"}}>
                                        <div className="donation-form-note"><FormControlLabel value={true}
                                                                                              control={<Checkbox
                                                                                                  id="includeMyNote"
                                                                                                  onChange={props.handleChange}
                                                                                                  name="includeMyNote"
                                                                                                  size="small"
                                                                                              />}
                                                                                              label="Please include this note:"/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} display="flex" style={{textAlign: "left"}}>
                                        <TextField name="includeMyGiftNote" size="small" fullWidth variant="outlined"
                                                   id="includeMyGiftNote" onChange={props.handleChange}/>
                                    </Grid>
                                </>
                            </Grid>
                        </RadioGroup>
                    </div>

                )}
                <FormControlLabel value="false" control={<Radio size="small" label="false"/>}
                                  label="No - please keep my gift hidden on this form"/>
            </RadioGroup>
        </div>
    )
}

export default DonationGoalNameEntryBlock;
