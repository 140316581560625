import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl} from "@material-ui/core";
import CustomPhoneInput from "../PatronInformationBlock/PhoneInput";
import CountryRegionMUISelectors from "../PatronInformationBlock/CountryRegionInput"
import Grid from "@material-ui/core/Grid";
import RegionInput from "../PatronInformationBlock/RegionInput";
import {Controller} from "react-hook-form";
import {CountryRegionData} from "react-country-region-selector";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const PersonBlock = (props) => {
    const [country, setCountry] = useState("United States");
    const [countryShortCode, setCountryShortCode] = useState('us');
    const useStyles = makeStyles((theme) => ({
        root: {
            margin: 'auto',
            display: 'block'
        },
        label: {
            height: '44px',
            verticalAlign: 'middle',
            display: 'inline'
        },
        patronFormInput: {
            verticalAlign: 'middle',
            display: 'inline'
        }
    }));

    const {register, errors, control} = props.form;

    const [selectedDate, setSelectedDate] = React.useState('');

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const setPhoneNumber = function () {

    }

    const updateShortCountryCode = function () {
        CountryRegionData.forEach(function (option, index) {
            if (option[0] === country) {
                setCountryShortCode(option[1].toLowerCase());
            }

            setCountryShortCode('us');
        })
    }

    const onCountryChanged = function (event) {
        updateShortCountryCode()
        if (event.target === undefined) {
            setCountry(event)
            props.handleChange({
                target: {
                    name: "patronCountry",
                    value: event[0]
                }
            });
            return
        }

        setCountry(event.target.value)
        props.handleChange({
            target: {
                name: "patronCountry",
                value: event.target.value[0]
            }
        });
    }

    function deepFind(obj, path) {
        var paths = path.split('.')
            , current = obj
            , i;

        for (i = 0; i < paths.length; ++i) {
            if (current[paths[i]] == undefined) {
                return undefined;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    }

    let phoneTypes = ["Home", "Mobile", "Work"];

    const [phoneTypeResponse, setPhoneTypeResponse] = useState("Home");

    const handlePhoneChange = function (event) {
        setPhoneTypeResponse(event.target.value);
        props.handleChange(event)
    }

    const classes = useStyles();
    return (
        <div className="block" id={"person-block-" + props.block.id}>
            <div id="patron-header"><h2>{props.block.label}</h2></div>
            <br/>
            <FormControl id="patron-form" component="fieldset" classes={{
                root: classes.root
            }}>
                <Grid container alignItems="center" spacing={3}>
                    {[...props.block.fields.keys()].map(key => (
                        <>
                            {props.block.fields[key]["__typename"] === "ComponentPerson2ComponentsNameFields" && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>First Name{props.block.fields[key]["required"] &&
                                            <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-first-input">
                                            <TextField name={"person-block-first-name-" + props.block.id}
                                                       onChange={props.handleChange}
                                                       error={deepFind(errors, "person-block-first-name-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]["required"]})}
                                                       size="small" fullWidth variant="outlined" id="patron-first"/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>Last Name{props.block.fields[key]["required"] &&
                                            <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-first-input">
                                            <TextField name={"person-block-last-name-" + props.block.id}
                                                       onChange={props.handleChange} size="small"
                                                       error={deepFind(errors, "person-block-last-name-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]["required"]})}
                                                       fullWidth variant="outlined" id="patron-first"/>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            {props.block.fields[key]["__typename"] === "ComponentPerson2ComponentsEmailAddressField" && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>Email{props.block.fields[key]["required"] &&
                                            <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-last-input">
                                            <TextField name={"person-block-email-" + props.block.id}
                                                       onChange={props.handleChange}
                                                       error={deepFind(errors, "person-block-first-name-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]["required"]})}
                                                       type="email" size="small" fullWidth variant="outlined"
                                                       id="patron-last"/>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            {props.block.fields[key]["__typename"] === "ComponentPerson2ComponentsRelationshipFields" && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div
                                            className={classes.label}>{props.block.fields[key]['label']}{props.block.fields[key]["required"] &&
                                            <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-email-input">
                                            <TextField name={"person-block-relationship-" + props.block.id}
                                                       onChange={props.handleChange}
                                                       error={deepFind(errors, "person-block-relationship-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]["required"]})}
                                                       size="small" fullWidth variant="outlined" id="patron-email"/>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            {props.block.fields[key]["__typename"] === "ComponentPerson2ComponentsPhoneFields" && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        {props.block.fields[key]["type"] === "home" && (
                                            <div className={classes.label}>Home
                                                Phone{props.block.fields[key]["required"] &&
                                                    <span style={{color: "red"}}>*</span>}:</div>
                                        )}
                                        {props.block.fields[key]["type"] === "mobile" && (
                                            <div className={classes.label}>Mobile
                                                Phone{props.block.fields[key]["required"] &&
                                                    <span style={{color: "red"}}>*</span>}:</div>
                                        )}
                                        {props.block.fields[key]["type"] === "work" && (
                                            <div className={classes.label}>Work
                                                Phone{props.block.fields[key]["required"] &&
                                                    <span style={{color: "red"}}>*</span>}:</div>
                                        )}
                                        {props.block.fields[key]["type"] === "custom" && (
                                            <div className={classes.label}>Phone{props.block.fields[key]["required"] &&
                                                    <span style={{color: "red"}}>*</span>}:</div>
                                        )}

                                    </Grid>
                                    <Grid item xs={12} sm={(props.block.fields[key]["type"] === "custom") ? 7 : 10}>
                                        <div className={classes.patronFormInput} id="patron-first-input">
                                            <TextField
                                                name={"person-block-" + props.block.fields[key]["type"] + "-phone-" + props.block.id}
                                                type="tel" size="small" fullWidth variant="outlined"
                                                id={"person-block-" + props.block.fields[key]["type"] + "-phone-" + props.block.id}
                                                placeholder='Enter phone number' onChange={props.handleChange}/>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            {props.block.fields[key]["type"] === "custom" && (
                                    <Grid container sm={2}>
                                        <Grid item xs={1} sm={4}>
                                            <div className={classes.label}>Type<span style={{color: "red"}}>*</span>:
                                            </div>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <FormControl>
                                                <Select
                                                    value={phoneTypeResponse}
                                                    name={"person-block-" + props.block.fields[key]["type"] + "-phone-type-" + props.block.id}
                                                    onChange={handlePhoneChange}
                                                    MenuProps={{
                                                        disableScrollLock: true,
                                                    }}
                                                    inputRef={register({required: true})}
                                                >
                                                    {phoneTypes.map((phoneType) => (
                                                        <MenuItem key={phoneType} value={phoneType}>
                                                            {phoneType}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {props.block.fields[key]["__typename"] === "ComponentPerson2ComponentsAddressFields" && (
                                <>

                                    <CountryRegionMUISelectors name={"person-block-country-" + props.block.id}
                                                               form={props.form} id="patron-country" placeholder="City"
                                                               country={country} handleChange={onCountryChanged}/>

                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>Address{props.block.fields[key]["required"] &&
                                            <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-address-input">
                                            <TextField name={"person-block-address-" + props.block.id}
                                                       onChange={props.handleChange}
                                                       error={deepFind(errors, "person-block-address-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]['required']})}
                                                       size="small" fullWidth variant="outlined" id="patron-address"/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>Address 2:</div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-address-input">
                                            <TextField name={"person-block-address-2-" + props.block.id}
                                                       onChange={props.handleChange} size="small" fullWidth
                                                       variant="outlined" id="patron-address-2"/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>City{props.block.fields[key]["required"] &&
                                            <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-city-input">
                                            <TextField name={"person-block-city-" + props.block.id}
                                                       onChange={props.handleChange}
                                                       error={deepFind(errors, "person-block-city-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]['required']})}
                                                       size="small" fullWidth variant="outlined" id="patron-city"/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>Zip{props.block.fields[key]["required"] &&
                                            <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-zip-input">
                                            <TextField name={"person-block-zip-" + props.block.id}
                                                       onChange={props.handleChange}
                                                       error={deepFind(errors, "person-block-zip-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]['required']})}
                                                       size="small" fullWidth variant="outlined" id="patron-zip"/>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            {props.block.fields[key]["__typename"] === "ComponentPerson2ComponentsDateOfBirthFields" && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>Date of
                                            Birth{props.block.fields[key]["required"] &&
                                                <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-first-input">
                                            <TextField name={"person-block-dob-" + props.block.id}
                                                       onChange={props.handleChange}
                                                       error={deepFind(errors, "person-block-dob-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]["required"]})}
                                                       size="small" placeholder="mm/dd/yyyy" fullWidth
                                                       variant="outlined" type='date' id="patron-dob"/>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            {props.block.fields[key]["__typename"] === "ComponentPerson2ComponentsBirthdayFields" && (
                                <>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.label}>Birthday{props.block.fields[key]["required"] &&
                                            <span style={{color: "red"}}>*</span>}:
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className={classes.patronFormInput} id="patron-first-input">
                                            <TextField name={"person-block-birthday-" + props.block.id}
                                                       onChange={props.handleChange}
                                                       error={deepFind(errors, "person-block-birthday-" + props.block.id)}
                                                       inputRef={register({required: props.block.fields[key]["required"]})}
                                                       size="small" placeholder="mm/dd" fullWidth variant="outlined"
                                                       type='date' id="patron-birthday"/>
                                        </div>
                                    </Grid>
                                </>
                            )}
                        </>
                    ))}
                </Grid>
            </FormControl>
        </div>
    )
}

export default PersonBlock;
