import React, {useContext, useState} from "react";
import {FormControl} from '@material-ui/core';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {makeStyles} from '@material-ui/core/styles';
import {CartContext} from "../../contexts/CartContext"
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const DonationBlock = (props) => {
    const [donationAmount, updateDonationValue] = useState("0");

    const cartContext = useContext(CartContext);

    const setDonationAmount = function(amount) {
        updateDonationValue(amount)
        cartContext.updateDonation(props.block.id, props.block.checkoutLabel, amount)
    };

    const handleClick = function (event) {
        if (event.target.value === donationAmount) {
            setDonationAmount(undefined)
        }
    }

    const handleChange = function (event) {
        if (event.target.value === donationAmount) {
            setDonationAmount(undefined);
            return;
        }
        setDonationAmount(event.target.value);
        cartContext.updateDonation(props.block.id, props.block.checkoutLabel, event.target.value)
    }

    const handleButtonClick = function (event) {
        const amount = stripMoneyFormat(event.currentTarget.value);
        setDonationAmount(amount);
    }

    const useStyles = makeStyles((theme) => ({
        donationButton: {
            marginTop: "20px;",
            marginBottom: "20px;"
        },
        description: {
            whiteSpace: "pre"
        },
        field: {
            display: "contents",
        },
        customDonation: {
            width: "400px",
            verticalAlign: "middle",
        }
    }));

    const stripMoneyFormat = function (string) {
        return string.replace('$', '').replace(',', '')
    }

    const moneyFormat = function(number) {
        let num = number.toFixed(2)
        let nf = new Intl.NumberFormat();
        return nf.format(num);
    }

    const donationAmounts = [...props.block.donation_amounts].sort((a, b) => {
        if (a.amount > b.amount) {
            return props.block.sort === 'ascending' ? 1 : -1
        } else {
            return props.block.sort === 'ascending' ? -1 : 1
        }
    })

    const classes = useStyles();

    return (
        <div className="block" id={"donation-block-" + props.block.id}>
            <h2>
                {props.block.name}
            </h2>
            <p id="donation-description">{props.block.donation_description}</p>
            <FormControl id="donation-form" component="fieldset" classes={{
                root: classes.root
            }}>
                <div id="donation-buttons">
                {props.block.donation_style === "radio" && (
                    <RadioGroup name="donationAmount" value={donationAmount} onClick={handleClick} onChange={handleChange}>
                        {donationAmounts.map(donation => (
                            <FormControlLabel
                                name={`donationAmount`}
                                value={donation.amount.toString()}
                                control={<Radio size='small' color="secondary"/>}
                                label={"$" + moneyFormat(donation.amount) + " " + (donation.description || "")}
                            />
                        ))}
                    </RadioGroup>
                )}
                <Grid container spacing={1}>

                    {props.block.donation_style === "horizontalButtons" && (
                        donationAmounts.map(donation => (
                            <Grid item xs={1}>
                                <Button className={donation.amount.toString() === donationAmount.toString() ? "donationButton selected" : "donationButton"}
                                        name={`donationAmount`}
                                        size="large"
                                        variant="outlined"s
                                        onClick={handleButtonClick}
                                        value={donation.amount}
                                        onChange={props.handleChange}
                                        color="secondary">{"$" + moneyFormat(donation.amount)}</Button>
                            </Grid>
                        ))
                    )}
                    </Grid>
                    {props.block.allows_any_donation_amount && (
                        <span id="custom-donation-amount" className={classes.customDonation}>
                        {props.block.label}: <TextField name="donationAmount" variant="outlined" id="donation-amount" onChange={handleChange} className={classes.field} InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            style: {
                                height: "30px",
                                width: "105px"
                            }
                        }}/>
                        </span>
                    )}
                </div>
            </FormControl>
        </div>
    )
}

export default DonationBlock;
