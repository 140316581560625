import {createContext, useReducer, useState} from "react";

export const FormContext = createContext(undefined);

export const FormProvider = ({ children }) => {
    const [form, setForm] = useState(undefined);
    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [zipcode, setZipcode] = useState(undefined);
    const [data, setData] = useState({});
    const venue = window.location.pathname.split("/")[1]
    const formReducer = (state, event) => {
        return {
            ...state,
            [event.name]: event.value,
            form: form,
            venue: venue
        }
    }

    const tunlUrl = function() {
        if (form !== undefined && form.testMode) {
            return "https://test-payment.tunl.com/CharlieF2"
        } else if(form !== undefined) {
            return "https://payment.tunl.com/" + form.venue.tunl_username.replace(":emv", "")
        }
    }

    const currentBuyer = function() {
        if (formData["buyersEmail"]) {
            return {
                "name": formData["buyersFirstName"] + " " + formData["buyersLastName"],
                "address": formData["buyersAddress"],
                "city": formData["buyersCity"],
                "region": formData["patronRegion"],
                "country": (formData["buyersCountry"] !== "United States") ? formData["buyersCountry"] : "USA",
                "zip": formData["buyersZip"],
                "email": formData["buyersEmail"]
            }
        } else {
            return {
                "name": formData["patronFirstName"] + " " + formData["patronLastName"],
                "address": formData["patronAddress"],
                "city": formData["patronCity"],
                "region": formData["patronRegion"],
                "country": (formData["patronCountry"] !== "United States") ? formData["patronCountry"] : "USA",
                "zip": formData["patronZip"],
                "email": formData["patronEmail"]
            }
        }
    }

    const [formData, setFormData] = useReducer(formReducer, {});

    function isDataCollectionFormType() {
        if (form === undefined) {
            console.log("form is undefined")
            return false
        }

        console.log("formType", form.formType)
        return ["DataCollection", "MailingList", "EmailList", "VolunteerList", "Rental", "TheaterRental", "Loyalty", "ClassWaitlist", "MovieWaitlist", "ShowWaitlist", "EventWaitlist"].indexOf(form.formType) > -1;
    }


    return (
        <FormContext.Provider
            value={{
                form,
                setForm,
                firstName,
                setFirstName,
                lastName,
                setLastName,
                zipcode,
                setZipcode,
                data,
                setData,
                formData,
                setFormData,
                venue,
                tunlUrl,
                currentBuyer,
                isDataCollectionFormType
            }}
        >
            {children}
        </FormContext.Provider>
    );
};
