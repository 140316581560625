import React from "react";
import Block from "../Block/Block";


const Page = ({page, form, handleChange, handleFormSubmission}) => {
    return (
        <div>
        {page.blocks && (
            page.blocks.map(block => (
                <Block block={block} form={form} handleChange={handleChange} handleFormSubmission={handleFormSubmission} />
            ))
        )}
        </div>
)
};

export default Page;