import React, {useContext, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl} from "@material-ui/core";
import {FormContext} from "../../contexts/FormContext";
import Grid from "@material-ui/core/Grid";
import {CountryRegionData} from "react-country-region-selector";
import CountryRegionMUISelectors from "../PatronInformationBlock/CountryRegionInput";
import RegionInput from "../PatronInformationBlock/RegionInput";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const BuyersBlock = (props) => {
    const formContext = useContext(FormContext);
    const [country, setCountry] = useState("United States");
    const [countryShortCode, setCountryShortCode] = useState('us');
    const [selectedDate, setSelectedDate] = React.useState('');
    const useStyles = makeStyles((theme) => ({
        root: {
            margin: 'auto',
            display: 'block'
        },
        label: {
            height: '44px',
            verticalAlign: 'middle',
            display: 'inline'
        },
        buyersFormInput: {
            verticalAlign: 'middle',
            display: 'inline'
        }
    }));

    const {register, errors, control} = props.form;

    const setFirstName = function (event) {
        formContext.setFirstName(event.target.value)
        props.handleChange(event);
    }

    const setLastName = function (event) {
        formContext.setLastName(event.target.value)
        props.handleChange(event);
    }

    const updateShortCountryCode = function () {
        CountryRegionData.forEach(function (option, index) {
            if (option[0] === country) {
                setCountryShortCode(option[1].toLowerCase());
            }

            setCountryShortCode('us');
        })
    }

    const onCountryChanged = function (event) {
        updateShortCountryCode()
        if (event.target === undefined) {
            setCountry(event)
            props.handleChange({
                target: {
                    name: "buyersCountry",
                    value: event[0]
                }
            });
            return
        }

        setCountry(event.target.value)
        props.handleChange({
            target: {
                name: "buyersCountry",
                value: event.target.value[0]
            }
        });
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const [phoneTypeResponse, setPhoneTypeResponse] = useState("Home");

    const handlePhoneChange = function (event) {
        setPhoneTypeResponse(event.target.value);
        props.handleChange(event)
    }

    let phoneTypes = ["Home", "Mobile", "Work"];


    const classes = useStyles();

    return (
        <div className="block" id={"buyers-block-" + props.block.id}>
            <div id="buyers-header"><h2>{props.block.label}</h2></div>
            <br/>
            <FormControl id="buyers-form" component="fieldset" classes={{
                root: classes.root
            }}>
                <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.label}>First Name<span style={{ color: "red" }}>*</span>:</div>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <div className={classes.buyersFormInput} id="buyers-first-input">
                            <TextField error={errors.buyersFirstName} name="buyersFirstName"
                                       inputRef={register({required: true})} size="small" fullWidth variant="outlined"
                                       id="buyers-first" onChange={setFirstName}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.label}>Last Name<span style={{ color: "red" }}>*</span>:</div>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <div className={classes.buyersFormInput} id="buyers-last-input">
                            <TextField error={errors.buyersLastName} name="buyersLastName"
                                       inputRef={register({required: true})} size="small" fullWidth variant="outlined"
                                       id="buyers-last" onChange={setLastName}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.label}>Email<span style={{ color: "red" }}>*</span>:</div>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <div className={classes.buyersFormInput} id="buyers-email-input">
                            <TextField error={errors.buyersEmail} name="buyersEmail"
                                       inputRef={register({required: true})} size="small" fullWidth variant="outlined"
                                       id="buyers-email" onChange={props.handleChange}/>
                        </div>
                    </Grid>
                    {props.block['BuyersBlockFields'] != null && props.block['BuyersBlockFields']['address'] && (
                        <>

                            <CountryRegionMUISelectors name="buyersCity" id="buyers-city" placeholder="City" country={country}
                                                       handleChange={onCountryChanged}/>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>Address{props.block['BuyersBlockFields']['address']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.buyersFormInput} id="buyers-address-input">
                                    <TextField error={errors.buyersAddress} name="buyersAddress"
                                               inputRef={register({required: props.block['BuyersBlockFields']['address']['required']})} size="small" fullWidth
                                               variant="outlined" id="buyers-address" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>Address 2:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.buyersFormInput} id="buyers-address-2-input">
                                    <TextField name='buyersAddress2' size="small" fullWidth variant="outlined" id="buyers-address-2" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>City{props.block['BuyersBlockFields']['address']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.buyersFormInput} id="buyers-address-input">
                                    <TextField error={errors.buyersCity} name="buyersCity"
                                               inputRef={register({required: props.block['BuyersBlockFields']['address']['required']})} size="small" fullWidth
                                               variant="outlined" id="buyers-address" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                            <RegionInput form={props.form} country={country} handleChange={props.handleChange}/>
                            <Grid item xs={12} sm={2}>
                                <div className={classes.label}>Zip{props.block['BuyersBlockFields']['address']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <div className={classes.buyersFormInput} id="buyers-zip-input">
                                    <TextField error={errors.buyersZip} name="buyersZip"
                                               inputRef={register({required: props.block['BuyersBlockFields']['address']['required']})} size="small" fullWidth
                                               variant="outlined" id="buyers-zip" onChange={props.handleChange}/>
                                </div>
                            </Grid>
                        </>
                    )}
                    {props.block['BuyersBlockFields'] != null && props.block['BuyersBlockFields']['phone'] && (
                            <>
                                <Grid item xs={12} sm={2}>
                                    {props.block['BuyersBlockFields']['phone']["type"] === "home" && (
                                        <div className={classes.label}>Home Phone{props.block['BuyersBlockFields']['phone']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                                    )}
                                    {props.block['BuyersBlockFields']['phone']["type"] === "mobile" && (
                                        <div className={classes.label}>Mobile Phone{props.block['BuyersBlockFields']['phone']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                                    )}
                                    {props.block['BuyersBlockFields']['phone']["type"] === "work" && (
                                        <div className={classes.label}>Work Phone{props.block['BuyersBlockFields']['phone']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                                    )}
                                    {props.block['BuyersBlockFields']['phone']["type"] === "custom" && (
                                        <div className={classes.label}>Phone{props.block['BuyersBlockFields']['phone']['required'] && <span style={{ color: "red" }}>*</span>}:</div>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={( props.block['BuyersBlockFields']['phone']["type"] === "custom") ? 7 : 10}>
                                    <TextField name={"buyersPhone" + props.block['BuyersBlockFields']['phone']["type"]} type="tel" size="small" fullWidth variant="outlined" id="buyers-phone" placeholder='Enter phone number' onChange={props.handleChange}/>
                                </Grid>
                                { props.block['BuyersBlockFields']['phone']["type"] === "custom" && (
                                    <Grid container sm={2}>
                                        <Grid item xs={1} sm={4}>
                                            <div className={classes.label}>Type<span style={{ color: "red" }}>*</span>:</div>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <FormControl>
                                                <Select
                                                    value={phoneTypeResponse}
                                                    name={"buyersPhone-type"}
                                                    onChange={handlePhoneChange}
                                                    MenuProps={{
                                                        disableScrollLock: true,
                                                    }}
                                                    inputRef={register({ required: true })}
                                                >
                                                    {phoneTypes.map((phoneType) => (
                                                        <MenuItem key={phoneType} value={phoneType}>
                                                            {phoneType}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        )}
                </Grid>
            </FormControl>
        </div>
    )
}

export default BuyersBlock;
